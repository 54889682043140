import isNaN from "lodash/isNaN";
import get from "lodash/get";
import store from './store';
import moment from 'moment';
import short from "short-uuid";
import Vue from "vue";
import router from "@/Routes";
import {ASYNC_LINE_STATES, ASYNC_LINE_TYPES} from "@/const";

export const getPrimaryPrice = (price) => (tax_ratio_percentage) => {
  return price
};

export const getTrans = (item, field_name) => {
  return get(item, `translations.${store.state.auth.current_language}.${field_name}`, '');
};

export const formatPrice = (price, price_type = null) => {
  return formatPriceWithCurrency(price, null, price_type, store.state.basket.priceFormatter);
};

export const formatPriceWithCurrencyParts = (price, currency_code = null, price_type = null, price_formatter = null) => {
  const priceAsFloat = parseFloat(price);

  let priceFormatter;
  if (price_formatter) {
    priceFormatter = price_formatter;
  } else {
    if (!currency_code || currency_code.trim() === '') {
      currency_code = get(store.state.basket, 'content.checkout.currency', 'EUR');
    }
    priceFormatter = price_formatter || new Intl.NumberFormat(store.state.auth.current_language, {
      style: 'currency',
      currency: currency_code,
    });
  }

  if (isNaN(priceAsFloat)) {
    return [];
  }
  let formattedPriceAsList = priceFormatter.formatToParts(priceAsFloat);
  if (price_type !== null) {
    const PRICE_TYPES = store.getters["auth/getUserConstants"]('PRICE_TYPES');
    const price_type_label = PRICE_TYPES?.[price_type]?.label;
    formattedPriceAsList.push({
      type: 'suffix',
      value: ` ${price_type_label}` || ''
    })
  }
  return formattedPriceAsList;
}

export const getPriceSuffix = (price_type) => {
  const PRICE_TYPES = store.getters["auth/getUserConstants"]('PRICE_TYPES');
  return PRICE_TYPES?.[price_type]?.label;
}

export const formatPriceWithCurrency = (price, currency_code = null, price_type = null, price_formatter = null) => {
  const formattedPriceAsList = formatPriceWithCurrencyParts(price, currency_code, price_type, price_formatter);
  return formattedPriceAsList.map(({type, value}) => {
    return value;
  }).reduce((string, part) => string + part, '');
};

export const formatPriceWithCurrencySmallType = (price, currency_code = null, price_type = null, price_formatter = null) => {
  const formattedPriceAsList = formatPriceWithCurrencyParts(price, currency_code, price_type, price_formatter);
  return formattedPriceAsList.map(({type, value}) => {
    switch (type) {
      case 'suffix'  :
        return `<small>${value}</small>`;
      default        :
        return value;
    }
  }).reduce((string, part) => string + part, '');
};

export const formatPercentage = (percentage) => {
  const percentageAsFloat = parseFloat(percentage);
  const percentageFormatter = new Intl.NumberFormat(store.state.auth.current_language, {
    style: 'percent'
  });
  return isNaN(percentageAsFloat) ? '' : percentageFormatter.format(percentageAsFloat / 100)
};

export const linkIcon = (route, target) => {
  if (['_blank', '_self', '_parent', '_top'].includes(target)) {
    return `<a href="${route}" target="${target}"><i class="fa fa-link"></i></a>`
  }
  return `<a href="${route}"><i class="fa fa-link"></i></a>`
}

export const formatMoment = (datetime) => (format) => {
  format = format || 'DD.MM.YYYY HH:mm:ss';
  if (datetime == null) {
    return '';
  }
  return moment(datetime).format(format)
};

export const formatDate = (datetime) => formatMoment(datetime)('DD.MM.YYYY');

export const formatFilterDate = (datetime) => formatMoment(datetime)('DD-MM-YYYY')

export const formatDateTime = (datetime) => formatMoment(datetime)('DD.MM.YYYY HH:mm:ss');

const translator = short();
export const urlIdToInternalId = (url_string) => translator.toUUID(url_string).toUpperCase();
export const internalIdToUrlId = (id) => translator.fromUUID(id);

export const getTrigramHighlight = (value, field_name, item) => (es_field_type) => {
  const highlight = get(item, 'highlight', null);
  let highlight_value = null;
  if (highlight) {
    highlight_value = get(highlight, `${field_name}.${es_field_type}`, null);
  }
  return highlight_value ? highlight_value[0] : value;
};

export const getTenantSlug = (hostname) => {
  let tenantSlug = ''
  if (hostname.includes('.')) {
    const regex = /.+\/\/|www.|\..+/g;
    const hostname_prefix = hostname.replace(regex, '');
    tenantSlug = hostname_prefix
  }
  return tenantSlug
}

const checkAuthOnly = (condition, auth) => {
  return !condition || (condition === 'anonymous' && !auth) || (condition === 'authenticated' && auth)
}

export const addRoutesFromModule = (module, is_user) => {
  module.routes.forEach((route) => {
    if (checkAuthOnly(route?.meta?.authCondition, is_user)) {
      router.addRoute('Layout', route)
    }
  })
  module.public_routes.forEach((route) => {
    if (checkAuthOnly(route?.meta?.authCondition, is_user)) {
      router.addRoute(route)
    }
  })
}

export const importConfig = (mapping, commit, state) => {
  return import(`@/config/${mapping}/index.js`).then((module) => {
    window.SB_CONFIG = module.default;
    Vue.prototype.SB_CONFIG = window.SB_CONFIG;
    commit("setUserFieldConfig", SB_CONFIG.getConfig(SB_CONFIG.MODEL.USERS, SB_CONFIG.CONFIG_TYPES.FIELD_MAPPING));
    window.ROUTES = module.routes.routes;
    if (!state.routes_updated) {
      addRoutesFromModule(module, !!state.user);
      state.routes_updated = true;
    }
  })
}

export const showErrorFromApi = (error_response) => {
  if (error_response.response?.data?.ErrorMsg) {
    Messenger().post({
      message: error_response.response.data.ErrorMsg,
      type: 'error',
      showCloseButton: true,
    });
  }
}

export const updateRoutes = (currentRoutes, routesToAdd = []) => {
  const newRoutes = currentRoutes
  routesToAdd.forEach((newRoute) => {
    if (newRoute.parent) {
      const parentIndex = newRoutes.findIndex(route => route.name === newRoute.parent)
      if (parentIndex >= 0) {
        const existingRouteIndex = (newRoutes[parentIndex]['children'] || []).findIndex(childRoute => childRoute.path === newRoute.route.path)
        if (!newRoutes[parentIndex]['children']) {
          newRoutes[parentIndex]['children'] = []
        }
        if (existingRouteIndex >= 0) {
          newRoutes[parentIndex]['children'][existingRouteIndex] = newRoute.route
        } else {
          newRoutes[parentIndex]['children'].push(newRoute.route)
        }
      } else {
        throw gettext('Parent route neexistuje.')
      }
    } else {
      const existingRouteIndex = newRoutes.findIndex(route => route.path === newRoute.route.path)
      if (existingRouteIndex >= 0) {
        newRoutes[existingRouteIndex] = newRoute.route
      } else {
        newRoutes.push(newRoute.route)
      }
    }
  })
  return newRoutes
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function removeTenantSuffix(name) {
  const regex = /_\d+$/g;
  return name.replace(regex, '')
}

function findPropertyFromList(obj, list) {
  for (let index in list) {
    const property = list[index]
    if (obj.hasOwnProperty(property)) {
      return obj[property]
    }
  }
  return '__notFound__'
}

function formatDataFromConfig(data, config_fields) {
  return data.map((item, index) => {
    const formatted_record = {}
    config_fields.forEach((config_field) => {
      let field_path = config_field.field.split('.')
      if (config_field?.hidden) {
        return
      }
      let value = item[field_path[0]]
      index = 1
      while (index < field_path.length && !!value) {
        value = value[field_path[index]]
        index++;
      }
      if (config_field?.formatter) {
        value = config_field.formatter(value, item, index, config_field.field)
      }
      formatted_record[config_field.title] = value
    })
    return formatted_record
  })
}

function createDataTree(dataset, sort_by = null) {
  let categories = []

  if (typeof dataset === 'object') {
    categories = Object.entries(dataset).map((category) => (category[1]));
  } else {
    categories = dataset
  }

  if (sort_by) {
    categories.sort(function (a, b) {
      return a[sort_by] - b[sort_by];
    });
  }

  const hashTable = Object.create(null);
  categories.forEach(aData => {
    hashTable[aData.key] = {...aData, childNodes: []}
  });
  const dataTree = [];
  categories.forEach(aData => {
    if ((aData.parent_id) && hashTable[aData.parent_id]) hashTable[aData.parent_id].childNodes.push(hashTable[aData.key])
    else dataTree.push(hashTable[aData.key])
  });
  return dataTree;
}

function getEmptyCustomerModel() {
  return {
    payment_address: null,
    delivery_address: null,
    branch_address: null,
    customer: null
  }
}

function createAddLine(uuid, product, qty) {
  return {
    id: uuid,
    added: false,
    b2b_min_quantity: product.b2b_min_quantity,
    step: product.b2b_min_quantity,
    discount_perc: 0,
    discounted: false,
    ean_code: '',
    edited: false,
    name: product.name,
    order_no: product.order_no,
    price: product.price,
    price_base: product.price_base,
    price_base_obj: product.price_base_obj,
    price_obj: product.price_obj,
    pricing_summary: null,
    product_id: product.id,
    quantity: qty,
    state: ASYNC_LINE_STATES.WAITING,
    type: ASYNC_LINE_TYPES.ADD_LINE
  }
}

function createChangeLine(hash, line_id, qty, name, product_id) {
  return {
    id: hash,
    name: `${gettext('Zmena počtu kusov')} => ${name} `,
    ean_code: '',
    line_id: line_id,
    product_id: product_id,
    quantity: qty,
    state: ASYNC_LINE_STATES.WAITING,
    type: ASYNC_LINE_TYPES.CHANGE_QTY
  }
}

function createRemoveLine(hash, line_id, name, product_id) {
  return {
    id: hash,
    name: `${gettext('Odstránenie položky')} => ${name}`,
    line_id: line_id,
    product_id: product_id,
    ean_code: '',
    quantity: 0,
    state: ASYNC_LINE_STATES.WAITING,
    type: ASYNC_LINE_TYPES.REMOVE_LINE
  }
}

function createRemoveProductLine(hash, product_id, name) {
  return {
    id: hash,
    name: `${gettext('Odstránenie položky')} => ${name}`,
    product_id: product_id,
    ean_code: '',
    quantity: 0,
    state: ASYNC_LINE_STATES.WAITING,
    type: ASYNC_LINE_TYPES.REMOVE_PRODUCT
  }
}

function getShortUuid() {
  const short = require('short-uuid');
  const translator = short();
  return translator.new();
}

function calcProductQtyWithMuliplier(package_qty) {
  /**
   * Function for calculating the amount according to the multiplier.
   *  - in the case of multiplier packages unit, it returns package_qty * product_multiplier
   *  - in the case of multiplier pieces unit, first check multiples of minimum pieces
   *    then adjust qty to % = 0 rounded UP
   **/

  const product_multiplier = store.getters["basket/get_product_multiplier"]
  const product_multiplier_unit = store.getters["basket/get_product_multiplier_unit"]

  if (package_qty <= 0) {
    return product_multiplier > 0 ? product_multiplier : 1
  }

  if (product_multiplier_unit?.is_package) {
    return product_multiplier * package_qty
  }

  if (product_multiplier % package_qty !== 0) {
    return Math.ceil(product_multiplier / package_qty) * package_qty
  } else {
    return product_multiplier
  }
}

function groupArrayByParams(array_obj, group_by, get_values = [], as_list=true) {
    /**
     *  The function returns aggregate values grouped by the group_by parameter. It's possible to define group_by as a set of fields separated by "__".
     *  In such a case, a virtual key is created from their combinations, and at the end, the list is sorted according to the first key from group_by.
    **/
    const groupKeys = group_by.split('__'); // Convert the string into an array of keys

    const grouped = array_obj.reduce((acc, item) => {
        // Create a virtual key based on the groupKeys array
        const groupValue = groupKeys.map(key => get(item, key, null)).join('_');

        // Initialize if not exists
        if (!acc[groupValue]) {
            acc[groupValue] = {};

            // Initialize each key in the grouped object separately
            groupKeys.forEach(key => {
                acc[groupValue][key] = get(item, key);
            });

            get_values.forEach(valueKey => {
                acc[groupValue][valueKey] = get(item, valueKey, 0);
            });
        } else {
            get_values.forEach(valueKey => {
                acc[groupValue][valueKey] += get(item, valueKey, 0);
            });
        }

        return acc;
    }, {});

    // sort values by first group_by element
    if(as_list) {
        const sortedArray = Object.values(grouped).sort((a, b) => {
            if (a[groupKeys[0]] < b[groupKeys[0]]) return -1;
            if (a[groupKeys[0]] > b[groupKeys[0]]) return 1;
            return 0;
        });
        return sortedArray;
    }
    return grouped;
}

function addAlpha(color, opacity) {
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

let SB_UTILS = function () {
  return {
    formatDateTime,
    formatDate,
    formatFilterDate,
    formatMoment,
    formatPercentage,
    linkIcon,
    formatPriceWithCurrency,
    formatPriceWithCurrencySmallType,
    getPriceSuffix,
    formatPrice,
    getPrimaryPrice,
    urlIdToInternalId,
    internalIdToUrlId,
    getTrigramHighlight,
    getTenantSlug,
    getTrans,
    updateRoutes,
    addRoutesFromModule,
    importConfig,
    groupBy,
    removeTenantSuffix,
    findPropertyFromList,
    formatDataFromConfig,
    createDataTree,
    getEmptyCustomerModel,
    createAddLine,
    createChangeLine,
    createRemoveLine,
    createRemoveProductLine,
    getShortUuid,
    calcProductQtyWithMuliplier,
    groupArrayByParams,
    addAlpha
  }
}();

window.SB_UTILS = SB_UTILS;